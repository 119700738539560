import React from 'react'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const CustomPaymentRespnse = () => {
    const navigate = useNavigate();

    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var status = urlParams.get('status');

    if(status==='success'){
        Swal.fire({
            title: 'Sucesss!',
            text: 'Topup successfully done.',
            icon: 'success',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                navigate(-1);
            }
        });
    }else if(status==='failed'){
        Swal.fire({
            title: 'Failed!',
            text: 'Topup request is failed.',
            icon: 'error',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                navigate(-1);
            }
        });
    }else{
        Swal.fire({
            title: 'Pending!',
            text: 'Topup request is pending.',
            icon: 'warning',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                navigate(-1);
            }
        });
    }

  return (
    <div></div>
  )
}

export default CustomPaymentRespnse