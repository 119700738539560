import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';

const API_URL = process.env.REACT_APP_API_URL;

const CheckOutPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { url, order_id } = location.state || {};

    const [status, setStatus] = useState('pending');
    const [timeRemaining, setTimeRemaining] = useState(180); // 3 minutes in seconds

    useEffect(() => {
        const interval = setInterval(() => {
            // Reduce time every second
            setTimeRemaining(prev => {
                if (prev <= 1) {
                    clearInterval(interval);
                    if (status === 'pending') {
                        Swal.fire('Warning!', 'The operation is still pending after 3 minutes.', 'warning');
                    }
                    return 0;
                }
                return prev - 1;
            });

            // Fetch status every 5 seconds
            if (timeRemaining % 5 === 0) {
                fetchStatus().then(newStatus => {
                    if (newStatus !== 'pending') {
                        clearInterval(interval);
                        setStatus(newStatus);
                        showAlert(newStatus);
                    }
                });
            }
        }, 1000); // Run every second

        return () => clearInterval(interval);
    }, [status, timeRemaining]);

    const fetchStatus = async () => {
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}top_up_status.php?token=${key}&version=1&order_id=${order_id}`); // Replace with your actual API endpoint
            if (response.ok) {
                const data = await response.json();
                showAlert(data.status);
                return data.status;
            } else {
                Swal.fire({
                    title: 'Warning!',
                    text: 'The operation is still pending.',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(-1);
                    }
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Warning!',
                text: 'The operation is still pending.',
                icon: 'warning',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(-1);
                }
            });
        }
    };

    const showAlert = (status) => {
        if (status === 'Success') {
            Swal.fire({
                title: 'Success',
                text: 'Topup succesfully done',
                icon: 'success',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(-1);
                }
            });
        } else if (status === 'failed') {
            Swal.fire({
                title: 'Failed!',
                text: 'Topup operation failed',
                icon: 'error',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(-1);
                }
            });
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                {url ? (
                    <>
                        <QRCode value={url} size={180} bgColor="#ffffff" fgColor="#000000" />
                    </>
                ) : (
                    <p>Cick below button</p>
                )}
            </div>
            <a style={{ textAlign: 'center', textDecoration: 'none', backgroundColor: 'var(--app-color)', padding: '10px', borderRadius: '5px', color: 'white', width: '80%', marginBottom: '30px', marginTop: '30px' }} href={url} className='cursor-pointer'>Pay now</a>
            <div style={{ textAlign: 'center' }}>
                <p>Time out: {formatTime(timeRemaining)}</p>
                {timeRemaining <= 0 && status === 'pending' ? (
                    <p>Status is still pending after 3 minutes.</p>
                ) : (
                    <p>Payment status: {status}</p>
                )}
            </div>
            <div style={{ marginTop: '50px', color: 'var(--app-color)', textAlign: 'center' }}>
                Don't close or refresh this window.
            </div>
        </div>
    )
}

export default CheckOutPage