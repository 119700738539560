import React from 'react'
import HeaderActivity from '../components/HeaderActivity'

const Aboutus = () => {
  return (
    <div>
      <HeaderActivity title='About us' />
      <div className='page-container'>
        <div style={{ margin: '10px' }}>
          <h3 style={{ color: 'var(--app-color)', marginBottom: '5px', textDecoration: 'underline', marginTop: '10px' }}>About us</h3>
          <p>Welcome to Round2Pay, your one-stop solution for quick and hassle-free mobile recharges. Our mission is to simplify your life by providing a seamless and secure platform for all your recharge needs. Whether you're topping up your phone, paying utility bills, or purchasing data packs, we ensure a smooth and efficient experience. With a user-friendly interface and a commitment to customer satisfaction, we aim to be the go-to recharge app for users everywhere. Join us and enjoy the convenience of instant recharges at your fingertips!</p>
        </div>
      </div>
    </div>
  )
}

export default Aboutus