// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activity-header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 55px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: var(--app-color);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 5px 0px;
    overflow-x: auto;
    gap: 10px;
    color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/style/com-activity-header.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,WAAW;IACX,YAAY;IACZ,sCAAsC;IACtC,kCAAkC;IAClC,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,oBAAoB;IACpB,gBAAgB;IAChB,SAAS;IACT,cAAc;AAClB","sourcesContent":[".activity-header {\n    position: fixed;\n    top: 0;\n    width: 100%;\n    height: 55px;\n    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);\n    background-color: var(--app-color);\n    display: flex;\n    align-items: center;\n    box-sizing: border-box;\n    padding: 0px 5px 0px;\n    overflow-x: auto;\n    gap: 10px;\n    color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
