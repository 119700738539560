import React from 'react'
import HeaderActivity from '../components/HeaderActivity'

const RefundPolicy = () => {
  return (
    <div>
      <HeaderActivity title='Refund Policy' />
      <div className='page-container'>
        <div style={{margin:'10px'}}>
          <h3 style={{color:'var(--app-color)',marginBottom:'5px', textDecoration:'underline'}}>Refund Policy for Round2Pay</h3>
          <p>In case of the wrong operator number, we can't refund the amount if the ''number'' was valid and recharge has already been done.</p>
          <p style={{marginTop:'10px'}}>If any user wants to close their account they should finish their Round2Pay wallet balance before closing, Because we can't transfer their Round2Pay wallet balance to bank account.</p>
          <p style={{marginTop:'10px'}}>In case of any pending or processing payment, it may take upto 3 working days to reflect on his/her Round2Pay wallet or may refund to his/her bank account.</p>
        </div>
      </div>
    </div>
  )
}

export default RefundPolicy