import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import '../style/com-activity-header.css'

const HeaderActivity = (props) => {
    const navigate = useNavigate();
    return (
        <div className='activity-header'>
            <ArrowBackIcon className='text-white cursor-pointer' onClick={() => navigate(-1)} />
            <div>
                <b>
                    {props.title}
                </b>
            </div>
        </div>
    )
}

export default HeaderActivity