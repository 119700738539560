import React, { useState } from 'react'
import HeaderLogin from "../components/HeaderLogin";
import UpdateIcon from '../assets/notebook.gif'
import '../style/login.css'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const API_URL = process.env.REACT_APP_API_URL;

function Registered(props) {
    const naviggate = useNavigate();
    const location = useLocation();
    const { number } = location.state || {};
    const [formValue, setFormValue] = useState({
        number: number,
        name: '',
        email: '',
        agreed: false
    });
    const [registerbtn, setRegisterBtn] = useState(false);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormValue({
            ...formValue,
            [name]: newValue,
        });
    };

    const HandleRegistered = (event) => {
        if (formValue.number.length === 10 && formValue.agreed && formValue.name !== "" && formValue.email !== "") {
            sentOtp(formValue.number);
        } else {
            toast.error('Enter valid input.');
        }
    }

    async function sentOtp(number) {
        setRegisterBtn(true);
        try {
            const response = await fetch(API_URL + 'register.php?mobile=' + number + '&name=' + formValue.name + '&email=' + formValue.email + '&version=1');
            const data = await response.json();
            if (data.status === 'Failed') {
                if (data.action === 'login') {
                    Swal.fire({
                        title: 'Login?',
                        text: data.message,
                        icon: 'question',
                        confirmButtonText: 'Login',
                        cancelButtonText: 'Cancel',
                        showCancelButton: true
                    }).then((result) => {
                        if (result.isConfirmed) {
                            naviggate('../login');
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire('Cancelled', 'Your registration was not completed', 'info');
                        }
                    });
                } else {
                    Swal.fire('Ohh no', data.message, 'error');
                }
            } else {
                naviggate('../otp-verification', { state: { number: number } });
            }
        } catch (error) {
            Swal.fire('Ohh no', 'Something went wrong.', 'error');
        } finally {
            setRegisterBtn(false);
        }
    }

    const checkPolicyPages = (event) => {
        naviggate(event.target.getAttribute('url'));
    }

    return (
        <div style={{ backgroundColor: 'white' }}>
            <HeaderLogin title='Register' />
            <div className='page-container'>
                <div className='form_div'>
                    <div className='content-center'>
                        <img src={UpdateIcon} alt='' className='updateIcon' />
                    </div>
                    <div>
                        <input
                            type="number"
                            name="number"
                            readOnly="readonly"
                            placeholder='Enter mobile'
                            className={'form-control'}
                            autoComplete='off'
                            value={formValue.number}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            name="name"
                            placeholder='Enter name'
                            className={'form-control'}
                            autoComplete='off'
                            value={formValue.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <input
                            type="email"
                            name="email"
                            placeholder='Enter email'
                            className={'form-control'}
                            value={formValue.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <label className="checkbox-label">
                        <input
                            type="checkbox"
                            name="agreed"
                            className="checkbox-input"
                            checked={formValue.agreed}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        I agree <span className='cursor-pointer' style={{ textDecoration: 'underline' }} url='../terms-condition' onClick={checkPolicyPages}>terms of  service</span> & <span className='cursor-pointer' style={{ textDecoration: 'underline' }} url='../privacy-policy' onClick={checkPolicyPages}>privacy policy</span>
                    </label>
                    <button className='form-btn' onClick={HandleRegistered}>
                        {registerbtn ? (
                            <div className="loading-btn"></div>
                        ) : (
                            <div className='btn-text'>Send OTP</div>
                        )}
                    </button>
                </div>
            </div>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 3000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }}
            />
        </div>
    )
}

export default Registered;