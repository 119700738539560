// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --app-color: #00BB76;
    --disabled-app-color: #53d3a4ae;
    --light-app-color: #E5F8F1;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,+BAA+B;IAC/B,0BAA0B;AAC9B","sourcesContent":[":root {\n    --app-color: #00BB76;\n    --disabled-app-color: #53d3a4ae;\n    --light-app-color: #E5F8F1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
