import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import HeaderActivity from '../components/HeaderActivity'
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import '../style/recharge.css'

const API_URL = process.env.REACT_APP_API_URL;


const Dthrecharge = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [rechargebtn, setRechargeBtn] = useState(false);
    const [planbtn, setPlanBtn] = useState(false);

    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    //var pass_type = urlParams.get('type');
    var pass_title = urlParams.get('title');
    const opertor_list = [
        { value: '10', label: 'Airtel Tv' },
        { value: '11', label: 'Dish Tv' },
        { value: '13', label: 'Tata Sky' },
        { value: '12', label: 'Sun Tv' },
        { value: '14', label: 'Videocon Tv' }
    ]

    const [formValue, setFormValue] = useState({
        number: '',
        operator: '',
        amount: ''
    });


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValue({
            ...formValue,
            [name]: value,
        });
    };

    async function recharge(number, operator, amount) {
        setRechargeBtn(true);
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}recharge.php?token=${key}&version=1&amount=${amount}&operator=${operator}&number=${number}`);
            const data = await response.json();
            toast.dismiss();
            if (data.status === '1') {
                Swal.fire({
                    title: 'Success',
                    text: 'Recharge succesfully done',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(-1);
                    }
                });
            } else if (data.status === '3') {
                Swal.fire({
                    title: 'Failed!',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(-1);
                    }
                });
            } else {
                Swal.fire({
                    title: 'Pending',
                    text: 'Recharge request is pending',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(-1);
                    }
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Pending',
                text: 'Recharge request is pending',
                icon: 'warning',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(-1);
                }
            });
        } finally {
            setRechargeBtn(false);
        }
    }

    const HandleContinueForm = (event) => {
        if (formValue.number !== '' && formValue.operator !== '' && formValue.amount !== '') {
            Swal.fire({
                title: 'Confirm?',
                text: 'Do you want to processed your recharge?',
                icon: 'question',
                confirmButtonText: 'Confirmed',
                cancelButtonText: 'Cancel',
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    recharge(formValue.number, formValue.operator, formValue.amount);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire('Cancelled', 'Your recharge was not completed', 'info');
                }
            });
        } else {
            toast.error('Enter valid input.');
        }
    }

    async function fetchPlan(number, operator) {
        setPlanBtn(true);
        try {
            Swal.fire({
                title: 'Failed!',
                text: 'DTH infomation fetch failed.',
                icon: 'error',
                confirmButtonText: 'OK'
            }).then((result) => {

            });
        } catch (error) {
            Swal.fire('Ohh no', 'Something went wrong.', 'error');
            toast.dismiss();
        }finally{
            setPlanBtn(false);
        }
    }

    const HandlePlanFetch = (event) => {
        if (formValue.number !== '' && formValue.operator !== '') {
            fetchPlan(formValue.number, formValue.operator);
        } else {
            toast.error('Enter number & operator');
        }
    }


    return (
        <div>
            <HeaderActivity title={pass_title} />
            <div className='page-container'>
                <div className='form_div'>
                    <div>
                        <input
                            type="number"
                            name="number"
                            onChange={handleChange}
                            value={formValue.number}
                            placeholder='Enter number'
                            className='form-control'
                            autoComplete='off'
                            required
                        />
                    </div>
                    <div>
                        <select
                            name="operator"
                            onChange={handleChange}
                            value={formValue.operator}
                            className='form-control-select'
                            required
                        >
                            <option value='' disabled>Choose operator</option>
                            {opertor_list.map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <input
                            type="number"
                            name="amount"
                            onChange={handleChange}
                            value={formValue.amount}
                            placeholder='Enter amount'
                            className='form-control'
                            autoComplete='off'
                            required
                        />
                    </div>
                    <button onClick={HandlePlanFetch} className='w-30 btn float-right'>
                        {planbtn ? (
                            <div className="loading-btn"></div>
                        ) : (
                            <div className='btn-text'>Plan</div>
                        )}
                    </button>
                    <button onClick={HandleContinueForm} disabled={rechargebtn} className='form-btn' style={{marginTop:'15px'}}>
                        {rechargebtn ? (
                            <div className="loading-btn"></div>
                        ) : (
                            <div className='btn-text'>Recharge Now</div>
                        )}
                    </button>
                </div>
                <Toaster
                    position="bottom-center"
                    reverseOrder={false}
                    gutter={8}
                    containerClassName=""
                    containerStyle={{}}
                    toastOptions={{
                        className: '',
                        duration: 3000,
                        style: {
                            background: '#363636',
                            color: '#fff',
                        },
                        success: {
                            duration: 3000,
                            theme: {
                                primary: 'green',
                                secondary: 'black',
                            },
                        },
                    }}
                />
            </div>
        </div>
    )
}

export default Dthrecharge