// src/Carousel.js
import React, { useState, useEffect, useRef } from 'react';
import '../style/com-carousel.css';
import Swal from 'sweetalert2';

const API_URL = process.env.REACT_APP_API_URL;

const Carousel = () => {
    const [images, setImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const carouselRef = useRef(null);
    const touchStartX = useRef(0);
    const touchEndX = useRef(0);

    useEffect(() => {
        const key = localStorage.getItem('key');

        const fetchBanner = async () => {
            try {
                const cachedData = localStorage.getItem('bannerImages');
                const cacheTime = localStorage.getItem('bannerImagesTime');
                const now = new Date().getTime();
                const sixHours = 6 * 60 * 60 * 1000;

                if (cachedData && cacheTime && (now - cacheTime < sixHours)) {
                    setImages(JSON.parse(cachedData));
                } else {
                    const response = await fetch(`${API_URL}banner.php?token=${key}&version=1`);
                    const data = await response.json();
                    if (data.status === 'Success') {
                        setImages(data.data1);
                        localStorage.setItem('bannerImages', JSON.stringify(data.data1));
                        localStorage.setItem('bannerImagesTime', now.toString());
                    } else {
                        Swal.fire('Ohh no', data.message, 'error');
                    }
                }
            } catch (err) {
                console.error('Fetch error:', err);
                Swal.fire('Ohh no', 'Something went wrong.', 'error');
            }
        };

        fetchBanner();
    }, []);

    useEffect(() => {
        if (images.length > 0) {
            const interval = setInterval(() => {
                setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
            }, 5000);

            return () => clearInterval(interval);
        }
    }, [images.length]);

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        touchEndX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
        if (touchStartX.current - touchEndX.current > 50) {
            // Swipe left (next image)
            setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
        }

        if (touchStartX.current - touchEndX.current < -50) {
            // Swipe right (previous image)
            setCurrentIndex(prevIndex => (prevIndex - 1 + images.length) % images.length);
        }
    };

    return (
        <div
            className="carousel"
            ref={carouselRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <div className="carousel-inner" style={{ transform: `translateX(-${currentIndex * 100}%)`, transition: 'transform 0.5s ease-in-out' }}>
                {images.map((image, index) => (
                    <div key={index} className="carousel-item" onClick={() => window.location.href = image.redirectUrl}>
                        <img
                            src={image.url}
                            alt={`Image ${index}`}
                            loading="lazy" // Lazy load images
                            className="lazy-load-image"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Carousel;
