// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.install_page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
}

.install_button{
    height: 40px;
    width: 120px;
}`, "",{"version":3,"sources":["webpack://./src/style/install.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".install_page {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 85vh;\n}\n\n.install_button{\n    height: 40px;\n    width: 120px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
