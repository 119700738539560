// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-container {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
}

.tabs-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.tabs-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.tab {
    margin-right: 10px;
}

.tab button {
    background-color: #efecec;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 15px;
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.tab button:hover {
    background-color: #d3cfcf;
}

.tab button.active {
    background-color: var(--app-color);
}

.tab-content-wrapper {
    overflow-y: auto;
    height: calc(100vh - 50px);
}

.tabs-all {
    margin: 10px;
}

.plan-row {
    padding: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    border-radius: 8px;
    padding: 10px;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}`, "",{"version":3,"sources":["webpack://./src/style/recharge.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA,6CAA6C;AAC7C;IACI,wBAAwB;IACxB,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,sCAAsC;IACtC,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,wCAAwC;IACxC,0BAA0B;AAC9B","sourcesContent":[".tabs-container {\n    display: flex;\n    overflow-x: scroll;\n    white-space: nowrap;\n}\n\n.tabs-container::-webkit-scrollbar {\n    display: none;\n}\n\n/* Hide scrollbar for IE, Edge, and Firefox */\n.tabs-container {\n    -ms-overflow-style: none;\n    /* IE and Edge */\n    scrollbar-width: none;\n    /* Firefox */\n}\n\n.tab {\n    margin-right: 10px;\n}\n\n.tab button {\n    background-color: #efecec;\n    border: none;\n    outline: none;\n    cursor: pointer;\n    padding: 10px 15px;\n    transition: background-color 0.3s ease;\n    border-radius: 5px;\n}\n\n.tab button:hover {\n    background-color: #d3cfcf;\n}\n\n.tab button.active {\n    background-color: var(--app-color);\n}\n\n.tab-content-wrapper {\n    overflow-y: auto;\n    height: calc(100vh - 50px);\n}\n\n.tabs-all {\n    margin: 10px;\n}\n\n.plan-row {\n    padding: 5px;\n    margin-bottom: 5px;\n    margin-top: 5px;\n    border-radius: 8px;\n    padding: 10px;\n    background-color: white;\n    border: 1px solid #ddd;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    transition: transform 0.2s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
