// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.share-btn {
    background-color: #ffffff;
    padding: 5px;
    border-radius: 5px;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: var(--app-color);
}`, "",{"version":3,"sources":["webpack://./src/style/com-share.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,qBAAqB;IACrB,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".share-btn {\n    background-color: #ffffff;\n    padding: 5px;\n    border-radius: 5px;\n    justify-content: center;\n    align-content: center;\n    align-items: center;\n    color: var(--app-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
