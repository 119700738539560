import React, { useState, useEffect } from 'react'
import HeaderActivity from '../components/HeaderActivity'
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const Recharge = () => {
    const [rechargebtn, setRechargeBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var pass_type = urlParams.get('type');
    var pass_title = urlParams.get('title');
    var opertor_list = [];
    if (pass_type === 'prepaid' && opertor_list.length === 0) {
        opertor_list = [
            { value: '2', label: 'Jio' },
            { value: '1', label: 'Airtel' },
            { value: '3', label: 'Vi' },
            { value: '4', label: 'Bsnl Toup' },
            { value: '5', label: 'Bsnl Combo' }
        ];
    } else if (pass_type === 'postpaid') {
        opertor_list = [
            { value: '7', label: 'Jio Postpaid' },
            { value: '6', label: 'Airtel Postpaid' },
            { value: '8', label: 'Vi Postpaid' },
            { value: '9', label: 'Bsnl Postpaid' }
        ];
    } else if (pass_type === 'google_play') {
        opertor_list = [
            { value: '15', label: 'Google Play' }
        ];
    }


    const [tabsData, setTabsData] = useState({});
    const [activeTab, setActiveTab] = useState(null);
    const [formValue, setFormValue] = useState({
        number: '',
        operator: '',
        amount: ''
    });

    function fetchPlan(number, operator) {
        if (pass_type != 'google_play' && pass_type != 'postpaid') {
            setLoading(true);
            const key = localStorage.getItem('key');
            const response = fetch(`${API_URL}plan-fetch.php?token=${key}&version=1&number=${number}&operator=${operator}`)
                .then(response => response.json())
                .then(data => {
                    setTabsData(data.records);
                    setActiveTab(Object.keys(data.records)[0]);
                })
                .catch(error => {
                    Swal.fire('Info!', 'Something went wrong', 'info');
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        const scrollToTop = () => {
            const scrollStep = -window.scrollY / 50;
            const scrollInterval = setInterval(() => {
                if (window.scrollY !== 0) {
                    window.scrollBy(0, scrollStep);
                } else {
                    clearInterval(scrollInterval);
                }
            }, 15);
        };
        scrollToTop();
    }, [formValue.amount]);
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const handleTabContentClick = (rs) => {
        setFormValue(prevState => ({
            ...prevState,
            amount: rs
        }));
    };

    async function fetchOperator(number) {
        if (pass_type != 'google_play' && pass_type != 'postpaid') {
            setLoading(true);
            try {
                const key = localStorage.getItem('key');
                const response = await fetch(`${API_URL}fetch_op.php?token=${key}&version=1&number=${number}`);
                const data = await response.json();
                if (data.status === 'Success') {
                    setFormValue(prevState => ({
                        ...prevState,
                        operator: data.data1
                    }));
                    fetchPlan(number, data.data1);

                }
            } catch (error) {
                Swal.fire('Ohh no', 'Something went wrong.', 'error');
            } finally {
                setLoading(false);
            }
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValue({
            ...formValue,
            [name]: value,
        });

        if (name === 'number') {
            if (value.length === 10) {
                fetchOperator(value);
            }
        }
        if (name === 'operator') {
            if (formValue.number.length === 10) {
                fetchPlan(formValue.number, value);
            }
        }
    };

    async function recharge(number, operator, amount) {
        setRechargeBtn(true);
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}recharge.php?token=${key}&version=1&amount=${amount}&operator=${operator}&number=${number}`);
            const data = await response.json();
            if (data.status === '1') {
                Swal.fire({
                    title: 'Success',
                    text: 'Recharge succesfully done',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(-1);
                    }
                });
            } else if (data.status === '3') {
                Swal.fire({
                    title: 'Failed!',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(-1);
                    }
                });
            } else {
                Swal.fire({
                    title: 'Pending',
                    text: 'Recharge request is pending',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(-1);
                    }
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Pending',
                text: 'Recharge request is pending',
                icon: 'warning',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(-1);
                }
            });
        } finally {
            setRechargeBtn(false);
        }
    }

    const HandleContinueForm = (event) => {
        if (formValue.number !== '' && formValue.number.length === 10 && formValue.operator !== '' && formValue.amount !== '') {
            Swal.fire({
                title: 'Confirm?',
                text: 'Do you want to processed your recharge?',
                icon: 'question',
                confirmButtonText: 'Confirmed',
                cancelButtonText: 'Cancel',
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    recharge(formValue.number, formValue.operator, formValue.amount);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire('Cancelled', 'Your recharge was not completed', 'info');
                }
            });
        } else {
            toast.error('Enter valid input.');
        }
    }

    const TabContent = ({ data, onTabContentClick }) => (
        <div className="tab-content">
            {Array.isArray(data) ? (
                data.map((item, index) => (
                    <div className='plan-row cursor-pointer' key={index} onClick={() => onTabContentClick(item.rs)}>
                        <p>Amount: <b>{item.rs}</b></p>
                        <p>Validity: <b>{item.validity}</b></p>
                        <p>Description: <small>{item.desc}</small></p>
                    </div>
                ))
            ) : (
                <p style={{ margin: '10px', alignItems: 'center', textAlign: 'center' }}>No plan found.</p>
            )}
        </div>
    );

    return (
        <div>
            <HeaderActivity title={pass_title} />
            <div className='page-container'>
                <div className='form_div'>
                    <div>
                        <input
                            type="number"
                            name="number"
                            onChange={handleChange}
                            value={formValue.number}
                            placeholder='Enter number'
                            className='form-control'
                            autoComplete='off'
                            required
                        />
                    </div>
                    <div>
                        <select
                            name="operator"
                            onChange={handleChange}
                            value={formValue.operator}
                            className='form-control-select'
                            required
                        >
                            <option value='' disabled>Choose operator</option>
                            {opertor_list.map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <input
                            type="number"
                            name="amount"
                            onChange={handleChange}
                            value={formValue.amount}
                            placeholder='Enter amount'
                            className='form-control'
                            autoComplete='off'
                            required
                        />
                    </div>
                    <button onClick={HandleContinueForm} className='form-btn' disabled={rechargebtn}>
                        {rechargebtn ? (
                            <div className="loading-btn"></div>
                        ) : (
                            <div className='btn-text'>Recharge Now</div>
                        )}
                    </button>
                </div>
                {loading ? (
                    <center className="loading"></center>
                ) : (
                    <div className='tabs-all'>
                        <div className="tabs-container">
                            {Object.keys(tabsData).map((tabName, index) => (
                                <div key={index} className="tab">
                                    <button
                                        className={activeTab === tabName ? "active" : ""}
                                        onClick={() => handleTabClick(tabName)}
                                    >
                                        {tabName}
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className="tab-content">
                            <TabContent data={tabsData[activeTab]} onTabContentClick={handleTabContentClick} />
                        </div>
                    </div>
                )}
                <Toaster
                    position="bottom-center"
                    reverseOrder={false}
                    gutter={8}
                    containerClassName=""
                    containerStyle={{}}
                    toastOptions={{
                        className: '',
                        duration: 3000,
                        style: {
                            background: '#363636',
                            color: '#fff',
                        },
                        success: {
                            duration: 3000,
                            theme: {
                                primary: 'green',
                                secondary: 'black',
                            },
                        },
                    }}
                />
            </div>
        </div>
    )
}

export default Recharge