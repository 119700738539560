import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import '../style/com-balance.css'

const API_URL = process.env.REACT_APP_API_URL;

const Balance = () => {
  const [balances, setBalances] = useState({
    topup: '0.00',
    balance: '0.00',
    topup_used: '0.00',
    offer: '0.00',
    hold: '0.00',
    topup_offer: '0.00',
    unsettled: '0.00'
  });
  useEffect(() => {
    const key = localStorage.getItem('key');
    const fetchBalance = async () => {
      try {
        const response = await fetch(`${API_URL}balance.php?token=${key}&version=1`);
        const data = await response.json();
        if (data.status === 'Success') {
          setBalances(data.data1);
        } else {
          Swal.fire('Ohh no', data.message, 'error');
        }
      } catch (err) {
        Swal.fire('Ohh no', 'Someting went wrong.', 'error');
      }
    };

    fetchBalance();
  }, []);
  return (
    <div>
      <div className='transaction-summary-container'>
        <div className='transaction-summary'>
          <div className='transaction-card' style={{backgroundColor:'#556B2F'}}>
            <p>Total topup</p>
            <p>{balances.topup}</p>
          </div>
          <div className='transaction-card' style={{backgroundColor:'#191970'}}>
            <p>Used topup</p>
            <p>{balances.topup_used}</p>
          </div>
          <div className='transaction-card' style={{backgroundColor:'#001f3f'}}>
            <p>Earned</p>
            <p>{balances.offer}</p>
          </div>
          <div className='transaction-card' style={{backgroundColor:'#673ab7'}}>
            <p>Hold</p>
            <p>{balances.hold}</p>
          </div>
          <div className='transaction-card' style={{backgroundColor:'#228b22'}}>
            <p>Bonus</p>
            <p>{balances.topup_offer}</p>
          </div>
          <div className='transaction-card' style={{backgroundColor:'#800000'}}>
            <p>Un-sellted</p>
            <p>{balances.unsettled}</p>
          </div>
          <div className='transaction-card' style={{backgroundColor:'#36454F'}}>
            <p>Remaing</p>
            <p>{balances.balance}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Balance