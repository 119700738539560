import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import '../style/install.css';
import HeaderLogin from '../components/HeaderLogin';

const InstallButton = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isInstalled, setIsInstalled] = useState(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        const handleAppInstalled = () => {
            setIsInstalled(true);
            setDeferredPrompt(null); 
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        window.addEventListener('appinstalled', handleAppInstalled);

        if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
            setIsInstalled(true);
        }

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
            window.removeEventListener('appinstalled', handleAppInstalled);
        };
    }, []);

    const handleInstallClick = async () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                console.log('User accepted the install prompt');
            } else {
                console.log('User dismissed the install prompt');
            }
            setDeferredPrompt(null); 
        }
    };

    return (
        <div>
            <HeaderLogin title="Install" />
            <div className="main-container">
                <div className="install_page">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleInstallClick}
                        startIcon={<GetAppIcon />}
                        disabled={isInstalled || !deferredPrompt}
                    >
                        {isInstalled ? 'Installed' : 'Install App'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default InstallButton;
