import React, { useState, useEffect } from 'react';
import ErrorIcon from '../assets/error.png';

const ErrorPage = (props) => {
    const useOnlineStatus = () => {
        const [isOnline, setIsOnline] = useState(navigator.onLine);
    
        useEffect(() => {
            const handleOnline = () => setIsOnline(true);
            const handleOffline = () => setIsOnline(false);
    
            window.addEventListener('online', handleOnline);
            window.addEventListener('offline', handleOffline);
    
            return () => {
                window.removeEventListener('online', handleOnline);
                window.removeEventListener('offline', handleOffline);
            };
        }, []);
    
        return isOnline;
    };


    const isOnline = useOnlineStatus();

    const pageReload = () => {
        const callback = props.callback;
        if (typeof callback === 'function') {
            callback();
        } else {
            console.error('callback is not a function');
        }
    };

    

    return (
        <div>
            <div className="error_center">
                <img src={ErrorIcon} style={{height:'70px', width:'70px',margin:'20px'}} alt='No file'/>
                <h2>{isOnline ? "Something went wrong!" : "No internet connection!"}</h2>
                <p style={{marginTop:'5px'}}>{props.message}</p>
                <button onClick={pageReload} style={{ margin: '10px', padding: '5px 15px', fontSize: '16px' }}>Retry</button>
            </div>
        </div>
    );
};

export default ErrorPage;
