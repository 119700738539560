// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel {
    height: 160px;
    width: calc(100% - 20px);
    margin: 0px 10px 10px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-item {
    min-width: 100%;
    height: 160px;
}

.carousel-item img {
    width: 100%;
    height: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./src/style/com-carousel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,wBAAwB;IACxB,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sCAAsC;AAC1C;;AAEA;IACI,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,uBAAuB;AAC3B","sourcesContent":[".carousel {\n    height: 160px;\n    width: calc(100% - 20px);\n    margin: 0px 10px 10px;\n    border-radius: 5px;\n    overflow: hidden;\n    position: relative;\n}\n\n.carousel-inner {\n    display: flex;\n    transition: transform 0.5s ease-in-out;\n}\n\n.carousel-item {\n    min-width: 100%;\n    height: 160px;\n}\n\n.carousel-item img {\n    width: 100%;\n    height: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
