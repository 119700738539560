import React, { useState, useEffect } from 'react'
import Menu from '../components/Menu'
import Header from '../components/Header'
import Carousel from '../components/Carousel';
import { Link, useNavigate } from 'react-router-dom';
import TransactionSummary from '../components/TransactionSummary';
import ShareLayout from '../components/ShareLayout';
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ShopIcon from '@mui/icons-material/Shop';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import PropaneTankIcon from '@mui/icons-material/PropaneTank';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import RouterIcon from '@mui/icons-material/Router';
import AppsIcon from '@mui/icons-material/Apps';
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import billImage from '../assets/bill_image.png'
import rechargeImage from '../assets/recharge_image.png'
import upgrade1Image from '../assets/upgrade_1.png'
import upgrade2Image from '../assets/upgrade_2.png'
import '../style/home.css'
import '../style/service.css'
import ErrorPage from '../components/ErrorPage';


const API_URL = process.env.REACT_APP_API_URL;

const Home = () => {
  document.title = "Home";
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: ''
  });

  const [note, setNote] = useState('All operator working fine. For any query contact us.')
  const navigator = useNavigate();
  const [error_page, setError] = useState(false);
  const [error_msg, setErrorMsg] = useState('');

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    const key = localStorage.getItem('key');
    const savedProfile = localStorage.getItem('profileData');
    if (savedProfile) {
      setFormData(JSON.parse(savedProfile));
      return;
    }

    if (localStorage.getItem('note')) {
      setNote(localStorage.getItem('note'));
    }

    try {
      const response = await fetch(`${API_URL}profile.php?token=${key}&version=1`);
      const data = await response.json();
      if (data.status === 'Success') {
        const profileData = {
          name: data.data1.name || '',
          email: data.data1.email || '',
          address: data.data1.address || '',
        };
        setFormData(profileData);
        localStorage.setItem('profileData', JSON.stringify(profileData));

        setError(false);
        setErrorMsg('');
      } else {
        setError(true);
        setErrorMsg(data.message);
      }
    } catch (err) {
      setError(true);
      setErrorMsg(err.message);
    }
  };

  async function UpgradePayment(amount) {
    toast.loading('Loading...', {
      duration: Infinity,
      position: 'top-right',
    });
    try {
      const key = localStorage.getItem('key');
      const response = await fetch(`${API_URL}other_top_up.php?token=${key}&version=1&amount=${amount}&desc=upgrade`); // Replace with your API endpoint
      const data = await response.json();
      if (data.status === 'Failed') {
        Swal.fire('Failed', data.message, 'error');
      } else {
        navigator('../check-out', { state: { url: data.url, order_id: data.order_id } });
      }
    } catch (error) {
      Swal.fire('Ohh no', 'Something went wrong.', 'error');
    } finally {
      toast.dismiss();
    }
  }

  function HandleUpgrade(event) {
    console.log(event);
    Swal.fire({
      title: 'Info!',
      text: 'Do you want to upgrade?',
      icon: 'info',
      confirmButtonText: 'Pay',
      cancelButtonText: 'Cancel',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        UpgradePayment(event);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your upgrade request was not completed', 'info');
      }
    });
  }

  return (
    <div>
      {error_page ? (
        <ErrorPage message={error_msg} callback={() => fetchProfile()} />
      ) : (
        <div>
          <Header />
          <Menu />
          <div className='main-container'>
            <div className='home-dashboard'>
              <center className='home-dashboard-content'>
                <p style={{ fontSize: '16px' }}>Hi, {formData.name}</p>
                <p style={{ fontSize: '12px' }}>Welcome to Round2Pay</p>
                <hr className='home-dashboard-divider' />
                <marquee>{note}</marquee>
              </center>
            </div>
            <TransactionSummary />
            <div className='card-service-item'>
              <div className='card-service-item-header'>
                <span className='card-service-header-bar'></span>
                <p><b>Services</b></p>
              </div>
              <div className='service-item-row'>
                <Link to='../recharge?type=prepaid&title=Prepaid' className='service-item-card'>
                  <div className='service-item-content'>
                    <SystemSecurityUpdateGoodIcon fontSize='large' />
                  </div>
                  <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Prepaid</p>
                </Link>
                <Link to='../recharge?type=postpaid&title=Postpaid' className='service-item-card'>
                  <div className='service-item-content'>
                    <SmartphoneIcon fontSize='large' />
                  </div>
                  <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Postpaid</p>
                </Link>
                <Link to='../dth-recharge?type=dth_recharge&title=DTH%20Recharge' className='service-item-card'>
                  <div className='service-item-content'>
                    <OndemandVideoIcon fontSize='large' />
                  </div>
                  <p style={{ fontSize: '11px', fontWeight: 'bold' }}>DTH</p>
                </Link>
                <Link to='../recharge?type=google_play&title=Google%20Play' className='service-item-card'>
                  <div className='service-item-content'>
                    <ShopIcon fontSize='large' />
                  </div>
                  <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Google Play</p>
                </Link>
              </div>
              <div className='service-item-row'>
                <Link to='../bbps-biller?category=Electricity&title=Electricity' className='service-item-card'>
                  <div className='service-item-content'>
                    <EmojiObjectsIcon fontSize='large' />
                  </div>
                  <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Electricity</p>
                </Link>
                <Link to='../bbps-biller?category=Fastag&title=Fastag' className='service-item-card'>
                  <div className='service-item-content'>
                    <CarCrashIcon fontSize='large' />
                  </div>
                  <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Fastag</p>
                </Link>
                <Link to='../bbps-biller?category=LPG%20Gas&title=LPG%20Gas' className='service-item-card'>
                  <div className='service-item-content'>
                    <PropaneTankIcon fontSize='large' />
                  </div>
                  <p style={{ fontSize: '11px', fontWeight: 'bold' }}>LPG Gas</p>
                </Link>
                <Link to='../service' className='service-item-card'>
                  <div className='service-item-content'>
                    <AppsIcon fontSize='large' />
                  </div>
                  <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Utilities</p>
                </Link>
              </div>
            </div>
            <Carousel />
            <div className='spotlight'>
              <div className='spotlight-container'>
                <Link to='../recharge?type=prepaid&title=Prepaid' style={{ marginLeft: '0px' }} className='spotlight-item bg-spotlight-1'>
                  <div className='header_icon'>
                    <div></div>
                    <img src={rechargeImage} alt='' loading="lazy" className="lazy-load-image" />
                  </div>
                  <h5>Recharge and earn topup 5% cashback.</h5>
                  <p>Tap and recharge using our application or website.</p>
                </Link>
                <div onClick={HandleUpgrade.bind(this, '10000')} className='spotlight-item bg-spotlight-2'>
                  <div className='header_icon'>
                    <div></div>
                    <img src={upgrade2Image} alt='' loading="lazy" className="lazy-load-image" />
                  </div>

                  <h5>Maintain 10000, extra 1% increment.</h5>
                  <p>Tap and pay the amount to upgrade your id. Limit: 10000/Day.</p>
                </div>
                <Link to='../coming-soon' className='spotlight-item bg-spotlight-3'>
                  <div className='header_icon'>
                    <div></div>
                    <img src={upgrade1Image} alt='' loading="lazy" className="lazy-load-image" />
                  </div>
                  <h5>Lighting speed billpay & earn upto 0.7</h5>
                  <p>Tap and pay your bill using our application or website.</p>
                </Link>
                <div onClick={HandleUpgrade.bind(this, '500')} style={{ marginRight: '0px' }} className='spotlight-item bg-spotlight-4'>
                  <div className='header_icon'>
                    <div></div>
                    <img src={billImage} alt='' loading="lazy" className="lazy-load-image" />
                  </div>
                  <h5>Upgrade to Golden retailer id. Fees 500.00</h5>
                  <p>Explore dedicated QR, support. Free all features on future update.</p>
                </div>
              </div>
            </div>
            <div className="last-card">
              <ShareLayout />
            </div>
          </div>
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: '',
              duration: 3000,
              style: {
                background: '#363636',
                color: '#fff',
              },
              success: {
                duration: 3000,
                theme: {
                  primary: 'green',
                  secondary: 'black',
                },
              },
            }}
          />
        </div>
      )
      }
    </div >
  )
}

export default Home