import React, { useState, useEffect } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import Swal from 'sweetalert2';
import '../style/com-share.css';

const API_URL = process.env.REACT_APP_API_URL;

const ShareLayout = () => {
    const [refer_code, setReferCode] = useState('');

    useEffect(() => {
        const key = localStorage.getItem('key');
        const referCode = localStorage.getItem('referCode');
        if (referCode) {
            setReferCode(referCode);
            return;
        }

        const fetchRefercode = async () => {
            try {
                const response = await fetch(`${API_URL}refer-key.php?token=${key}&version=1`);
                const data = await response.json();
                if (data.status === 'Success') {
                    setReferCode(data.data1);
                    localStorage.setItem('referCode', data.data1);
                } else {
                    Swal.fire('Ohh no', data.message, 'error');
                }
            } catch (err) {
                Swal.fire('Ohh no', 'Someting went wrong.', 'error');
            }
        };

        fetchRefercode();
    }, []);

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share & earn!',
                    text: 'Download our Recharge app and use the promo code ' + refer_code + ' to get an exclusive upto 5% discount on your recharges!',
                    url: 'https://app.round2pay.in/login',
                });
            } catch (error) {
                console.log("Refer not done yet.")
            }
        } else {
            if (window.Android && window.Android.share) {
                window.Android.share(
                    'Share & earn!',
                    'Download our Recharge app and use the promo code ' + refer_code + ' to get an exclusive upto 5% discount on your recharges!',
                    'https://app.round2pay.in/login'
                );
            } else {
                Swal.fire('Ohh no', 'Browser not support share features.', 'error');
            }
        }
    };


    return (
        <div style={{margin:'0px 5px 0px'}}>
            <div className='card-profile' style={{backgroundColor:'var(--app-color)',color:'white'}}>
                <div className='card-profile-header'>
                    <p style={{ fontSize: '16px' }}><b>Refer & Earn</b></p>
                    <p style={{ fontSize: '12px',fontFamily:'fantasy', marginTop:'2px', marginBottom:'2px' }}>Share Round2Pay with your friens and other, earn uptop 20, When they add 500 and more.</p>
                    <p style={{ fontSize: '16px' }}>Refer code : <b style={{fontFamily:'monospace'}}>{refer_code}</b></p>
                </div>
                <div className='share-btn' >
                    <ShareIcon onClick={handleShare} />
                </div>
            </div>
        </div>
    )
}

export default ShareLayout