import React from 'react'
import HeaderActivity from '../components/HeaderActivity'

const PrivacyPolicy = () => {
  return (
    <div>
      <HeaderActivity title='Privacy Policy' />
      <div className='page-container'>
        <div style={{margin:'10px'}}>
          <span style={{}}>Last updated - 02/06/2024</span>
          <h3 style={{color:'var(--app-color)',marginBottom:'5px', textDecoration:'underline',marginTop:'10px'}}>Privacy Policy for Round2Pay</h3>
          <p style={{marginTop:'7px'}}>At Round2Pay, accessible from https://round2pay.in/, one of our main priorities is the Privacy Policy of our visitors. This Privacy Policy document contains all type of information that is collected and recored by Round2Pay and how we use it and how it is safe from others.</p>
          <p style={{marginTop:'7px'}}>If you have additional queries or require more information about our Privacy Policy, then please contact us on given contact us section (by address or email or phone) that is mantioned below.</p>
          <p style={{marginTop:'7px'}}>The Privacy Policy applies only to our online activities and is valid for visitors to our website(https://round2pay.in/) and Application with regards to the information that they shared and/or collect in Round2Pay. The Privacy Policy is not applicable to any information collected offline or via channels other than this website(https://round2pay.in/) and the Application.</p>
          <p style={{marginTop:'7px'}}>Before changing any things we will always notify you.</p>

          <h4 style={{marginTop:'10px'}}>Consent</h4>
          <p style={{marginTop:'7px'}}>By using our website, you hereby consent to our Privacy Policy and agrees to its terms.</p>
          <h4 style={{marginTop:'10px'}}>Information we collect</h4>
          <p style={{marginTop:'7px'}}>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
          <p style={{marginTop:'7px'}}>The contact information that you are asked to provide, and the reasons is to correctness of saved contact number for recharge and other services.</p>
          <p style={{marginTop:'7px'}}>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
          <p style={{marginTop:'7px'}}>When you verified(OTP verification) your number at first time on Website(https://round2pay.in/) or Application it automatically register an Account and for further we may ask for your contact information, including items such as name, company name, address, email address, WhatsApp number and telephone number.</p>
          <h4 style={{marginTop:'10px'}}>How we use your information</h4>
          <p style={{marginTop:'7px'}}>We use the information we collect in various ways, including to:</p>
          <ul style={{listStyleType:'none'}}>
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our website</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
          </ul>
          <h4 style={{marginTop:'10px'}}>Log Files</h4>
          <p style={{marginTop:'7px'}}>Round2Pay follows a standard procedure of using log files. These files log visitors when they visit websites(https://round2pay.in/) and Application. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
          <h4 style={{marginTop:'10px'}}>Cookies and Web Beacons</h4>
          <p style={{marginTop:'7px'}}>Like any other website, Round2Pay uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
          <p style={{marginTop:'7px'}}>For more general information on cookies, please read the <a href="https://www.termsfeed.com/blog/sample-cookies-policy-template/#What_Are_Cookies">Cookies article on TermsFeed website.</a></p>
          <h4 style={{marginTop:'10px'}}>Advertising Partners Privacy Policies</h4>
          <p style={{marginTop:'7px'}}>We do not promotes any kinds of advertisment of own or third parties</p>
          <h4 style={{marginTop:'10px'}}>Third Party Privacy Policies</h4>
          <p style={{marginTop:'7px'}}>Round2Pay's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p>
          <p style={{marginTop:'7px'}}>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>
          <h4 style={{marginTop:'10px'}}>CCPA Privacy Rights (Do Not Sell My Personal Information)</h4>
          <p style={{marginTop:'7px'}}>Under the CCPA, among other rights, California consumers have the right to:</p>
          <ul style={{listStyleType:'none'}}>
            <li>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li>
            <li>Request that a business delete any personal data about the consumer that a business has collected.</li>
            <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
            <li>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</li>
          </ul>
          <h4 style={{marginTop:'10px'}}>GDPR Data Protection Rights</h4>
          <p style={{marginTop:'7px'}}>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
          <ul style={{listStyleType:'none'}}>
            <li>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</li>
            <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</li>
            <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
            <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
            <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
            <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
            <li>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</li>
          </ul>
          <h4 style={{marginTop:'10px'}}>Children's Information</h4>
          <p style={{marginTop:'7px'}}>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
          <p style={{marginTop:'7px'}}>Round2Pay does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
          <h4 style={{marginTop:'10px'}}>Contact Information</h4>
          <ul style={{listStyleType:'none'}}>
            <li>Email : round2pay.help@gmail.com</li>
            <li>Phone : (+91) 9365773618</li>
            <li>Address : Dalgaon Town, Darrang, IN 784116</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy