import React from 'react'
import HeaderActivity from '../components/HeaderActivity'

const TermsService = () => {
  return (
    <div>
      <HeaderActivity title='Terms & Service' />
      <div className='page-container'>
        <div style={{margin:'10px'}}>
          <span style={{}}>Last updated - 02/06/2024</span>
          <h3 style={{color:'var(--app-color)',marginBottom:'5px', textDecoration:'underline',marginTop:'10px'}}>Terms and Conditions</h3>
          <p style={{marginTop:'7px'}}><b>By using this website/app and its offered services, you are supposed to have read and agreed to the following terms and conditions:</b></p>

          <p style={{marginTop:'7px'}}>This website/app is operated by Round2Pay throughout the website/app, the terms “we”, “us” and “our” refer to telecom Company offers this website/app, including all information services available from this Website/app to you, the user, conditioned upon your acceptance of all terms, conditions, policies, and notices stated here.</p>

          <p style={{marginTop:'7px'}}>By visiting our website/app you engage in our “Service” or purchasing something from us and agree to be bound by the following terms and conditions. These Terms of Service apply to all users of the website/app, including without limitation users who are browsers, vendors, customers, merchants, and or contributors of content.</p>

          <p style={{marginTop:'7px'}}>Any new features or tools which are added to the current website/app shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website/app. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website/app following the posting of any changes constitutes acceptance of those changes.</p>

          <p style={{marginTop:'7px'}}>A user account is available only through the registration process once you submit the requested information while registering. Before registration, we ask whether or not you agree to the current Terms of Service. If you don’t agree to our Terms of Service you will not be permitted to use the services. As a user' you’re not allowed to run any paid ads on any platform (Google, Facebook, etc.) pointing to Round2Pay.in. If any user found with this then it would result in immediate termination of his/her account and a legal action may be taken against the user.</p>

          <p style={{marginTop:'7px'}}>We accept UPI transactions as mode of payments which are processed through our payment gateway provider. No information pertaining to payment is shared with us as you fill all the payment-related information on your bank’s website/app. Providers may consider the amount of bill paid as per their discretion. We are not responsible for any type of non-consideration of the bill paid from the operators end.</p>

          <p style={{marginTop:'7px'}}>We reserve the right to refuse service to anyone for any reason at any time.</p>

          <p style={{marginTop:'7px'}}>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website/app through which the service is provided, without express written permission by us.</p>

          <p style={{marginTop:'7px'}}>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</p>

          <p style={{marginTop:'7px'}}>The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.</p>

          <p style={{marginTop:'7px'}}>These Terms of Service and any policies or operating rules posted by us on this website/app or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).</p>

          <p style={{marginTop:'7px'}}>Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.</p>

          <p style={{marginTop:'7px'}}>Terms of Service and any separate agreements whereby we provide you services are governed by the law of India.</p>

          <p style={{marginTop:'7px'}}>You can review the most current version of the Terms of Service at any time on this page.</p>

          <p style={{marginTop:'7px'}}>We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website/app. It is your responsibility to check our website/app periodically for changes. Your continued use of or access to our website/app or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.</p>

          <p style={{marginTop:'7px'}}>Users shall take sole responsibility for the privacy and confidentiality of their Round2Pay account and password.</p>

          <p style={{marginTop:'7px'}}>All recharge plans/offers are provided by 3rd party API provider. We are not responsible for any type of wrong plan info. Before selecting any plan please do confirm from your operator.</p>

          <p style={{marginTop:'7px'}}>Round2Pay does not register or accept any complaints on successfull transactions, older than 30 days.</p>

          <p style={{marginTop:'7px'}}>In case of any fraudulent transaction, the user may send his/her full KYC details.</p>
        </div>
      </div>
    </div>
  )
}

export default TermsService