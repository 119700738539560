import React from 'react'
import '../style/com-login-header.css'

const HeaderLogin = (props) => {
    return (
        <div className='login-header'>
            <div>
                <b>
                    {props.title}
                </b>
            </div>
        </div>
    )
}

export default HeaderLogin