import React, { useState, useEffect } from 'react';
import HeaderActivity from '../components/HeaderActivity';
import UpdateIcon from '../assets/notebook.gif'
import Swal from 'sweetalert2'
import toast, { Toaster } from 'react-hot-toast';
import '../style/profile.css'
import ErrorPage from '../components/ErrorPage';

const API_URL = process.env.REACT_APP_API_URL;

function Profile() {
  const [loading, setLoading] = useState(false);
  const [error_page, setError] = useState(false);
  const [error_msg, setErrorMsg] = useState('');
  const [updatebtn, setUpdateBtn] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    if (formData.name !== "" && formData.email !== "" && formData.address !== "") {
      updateProfile();
    } else {
      toast.error('Enter valid input.');
    }
  };

  const updateProfile = async () => {
    setUpdateBtn(true);
    const key = localStorage.getItem('key');
    try {
      const response = await fetch(`${API_URL}update-profile.php?token=${key}&name=${formData.name}&email=${formData.email}&address=${formData.address}&version=1`);
      const data = await response.json();
      toast.dismiss();
      if (data.status === 'Success') {
        localStorage.setItem('profileData', JSON.stringify(formData));
        Swal.fire('Success', 'Successfully updated', 'success');
      } else {
        Swal.fire('Ohh no', data.message, 'error');
      }
    } catch (err) {
      Swal.fire('Ohh no', 'Something went wrong.', 'error');
      toast.dismiss();
    } finally {
      setUpdateBtn(false);
    }
  };


  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    const key = localStorage.getItem('key');
    const savedProfile = localStorage.getItem('profileData');
    if (savedProfile) {
      setFormData(JSON.parse(savedProfile));
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${API_URL}profile.php?token=${key}&version=1`);
      const data = await response.json();
      if (data.status === 'Success') {
        const profileData = {
          name: data.data1.name || '',
          email: data.data1.email || '',
          address: data.data1.address || '',
        };
        setFormData(profileData);
        localStorage.setItem('profileData', JSON.stringify(profileData));

        setError(false);
        setErrorMsg('');
      } else {
        setError(true);
        setErrorMsg(data.message);
      }
    } catch (err) {
      setError(true);
      setErrorMsg(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="loading_center">
          <div className="loading"></div>
        </div>
      ) : error_page ? (
        <ErrorPage message={error_msg} callback={() => fetchProfile()}/>
      ) : (
        <div style={{ backgroundColor: 'white' }}>
          <HeaderActivity title='Profile' />
          <div className='page-container'>
            <div className='form_div'>
              <div className='content-center'>
                <img src={UpdateIcon} alt='' className='updateIcon' />
              </div>
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder='Enter name'
                  className='form-control'
                  autoComplete='off'
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder='Enter email'
                  className='form-control'
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <textarea
                  name='address'
                  placeholder='Enter address'
                  value={formData.address}
                  onChange={handleChange}
                  required
                  rows={4}
                  cols={40}
                  className='form-control-textarea'
                />
              </div>
              <button type="submit" onClick={handleSubmit} disabled={updatebtn} className='form-btn'>
                {updatebtn ? (
                  <div className="loading-btn"></div>
                ) : (
                  <div className='btn-text'>Update</div>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: '',
          duration: 3000,
          style: {
            background: '#363636',
            color: '#fff',
          },
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
    </div>
  );
}

export default Profile;
