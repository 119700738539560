import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderActivity from '../components/HeaderActivity';
import Swal from 'sweetalert2';
import '../style/biller-file.css';
import ErrorPage from '../components/ErrorPage';

const API_URL = process.env.REACT_APP_API_URL;

const BbpsForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [jsonData, setJsonData] = useState(null);
    const [formData, setFormData] = useState({});
    const [billerId, setBillerId] = useState('');
    const [billDetails, setBillDetails] = useState(null);
    const [finalInputParams, setfinalInputParams] = useState('');
    const [finalInputValues, setfinalInputValues] = useState('');
    const [finalAmount, setfinalAmount] = useState(0);
    const [billfetchbtn, setBillfetchBtn] = useState(false);
    const [billpaybtn, setBillpayBtn] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error_page, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        if (params.get('biller_id')) {
            setBillerId(params.get('biller_id'));
            fetchInputs(params.get('biller_id'));
        } else {
            Swal.fire({
                title: 'Failed!',
                text: 'Technical issue occurs.',
                icon: 'error',
                confirmButtonText: 'OK'
            }).then(() => {
                navigate(-1);
            });
        }
    }, [location, navigate]);

    const fetchInputs = async (biller_id) => {
        setLoading(true);
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}bbps-form.php?token=${key}&version=1&biller_id=${biller_id}`);
            if (!response.ok) throw new Error('Failed to fetch form inputs');
            const data = await response.json();
            console.log('Fetched data:', data); // Log the fetched data
            if (data.status === 'Success') {
                setJsonData(data);
                initializeFormData(data);

                setError(false);
                setErrorMsg('');
            } else {
                setError(true);
                setErrorMsg(data.message);
            }
        } catch (error) {
            setError(true);
            setErrorMsg(error.message);
        } finally {
            setLoading(false);
        }
    };

    const initializeFormData = (data) => {
        if (!data || !data.data1 || data.data1.length === 0) {
            console.error('Invalid data structure:', data); // Log invalid data structure
            return;
        }
        const billerData = data.data1[0];
        const nparams = parseInt(billerData.nparam, 10);
        const initialFormData = {};
        for (let i = 1; i <= nparams; i++) {
            initialFormData[`param${i}`] = '';
        }
        setFormData(initialFormData);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let combinedString = '';
        let combineValue = '';
        const billerData = jsonData.data1[0];
        const nparams = parseInt(billerData.nparam, 10);

        for (let i = 1; i <= nparams; i++) {
            const paramName = billerData[`paramName_${i}`];
            const paramValue = formData[`param${i}`];
            combinedString += `<input><paramName>${paramName}</paramName><paramValue>${paramValue}</paramValue></input>`;
            if (combineValue === '') {
                combineValue = paramValue;
            } else {
                combineValue = combineValue + ' ' + paramValue;
            }
        }
        setfinalInputValues(combineValue);
        fetchBill(combinedString);
    };

    const fetchBill = async (combinedString) => {
        setBillfetchBtn(true);
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}bbps-bill-info.php?token=${key}&version=1&biller_id=${billerId}&passData=${combinedString}`);
            const data = await response.json();
            if (data.status === 'Success') {
                setfinalInputParams(combinedString);
                setBillDetails(data.data1);
                setfinalAmount(data.data1.amount);
            } else {
                Swal.fire({
                    title: 'Failed!',
                    text: data.message,
                    icon: 'error'
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Failed!',
                text: error.message || 'Technical issue occurs.',
                icon: 'error',
                confirmButtonText: 'OK'
            }).then(() => {
                navigate(-1);
            });
        } finally {
            setBillfetchBtn(false);
        }
    };

    const handlePayment = () => {
        if (finalAmount !== '0' && finalAmount !== '' && billerId !== '' && finalInputParams !== '' && billDetails.biller_response !== '' && billDetails.requestId !== '') {
            Swal.fire({
                title: 'Confirm?',
                text: 'Do you want to processed your bill payment?',
                icon: 'question',
                confirmButtonText: 'Confirmed',
                cancelButtonText: 'Cancel',
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    billPayment();
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire('Cancelled', 'Your bill payment was not completed', 'info');
                }
            });
        } else {
            Swal.fire('Error', 'Enter valid details', 'error');
        }
    };

    async function billPayment() {
        setBillpayBtn(true);
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}bbps-bill-pay.php?token=${key}&version=1&biller_id=${billerId}&xmldata=${finalInputParams}&biller_response=${billDetails.biller_response}&additional_response=${billDetails.additional_response}&amount=${finalAmount}&requestId=${billDetails.requestId}&input_value=${finalInputValues}`);
            const data = await response.json();
            if (data.status === '1') {
                Swal.fire({
                    title: 'Success',
                    text: 'Bill payment succesfully done',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(-1);
                    }
                });
            } else if (data.status === '3') {
                Swal.fire({
                    title: 'Failed!',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(-1);
                    }
                });
            } else {
                Swal.fire({
                    title: 'Pending',
                    text: 'Recharge request is pending',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(-1);
                    }
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Pending!',
                text: 'Your bill payment is pending.',
                icon: 'warning',
                confirmButtonText: 'OK'
            }).then(() => {
                navigate(-1);
            });
        } finally {
            setBillpayBtn(false);
        }
    }

    const finalAmountHandle = (event) => {
        setfinalAmount(event.target.value);
    }

    return (
        <div>
            {loading ? (
                <div className="loading_center">
                    <div className="loading"></div>
                </div>
            ) : error_page ? (
                <ErrorPage message={error_msg} callback={() => fetchInputs(billerId)}/>
            ) : (
                <div>
                    <HeaderActivity title='Form' />
                    <div className='page-container'>
                        {jsonData && jsonData.data1 && jsonData.data1.length > 0 && (
                            <div key={billerId} className="company-card">
                                <img src={jsonData.data1[0].logo} alt='' className="company-logo" />
                                <h5>{jsonData.data1[0].biller_name}</h5>
                            </div>
                        )}
                        <div className='form_div'>
                            <form onSubmit={handleSubmit}>
                                <div>
                                    {jsonData && jsonData.data1 && jsonData.data1.length > 0 && jsonData.data1[0].nparam && [...Array(parseInt(jsonData.data1[0].nparam, 10))].map((_, i) => {
                                        const paramIndex = i + 1;
                                        const paramNote = jsonData.data1[0][`paramNote_${paramIndex}`];
                                        const paramRegEx = jsonData.data1[0][`paramRegEx_${paramIndex}`];
                                        const paramIsOptional = jsonData.data1[0][`paramIsOptional_${paramIndex}`] === 'false';
                                        const paramMinL = jsonData.data1[0][`paramMinL_${paramIndex}`];
                                        const paramMaxL = jsonData.data1[0][`paramMaxL_${paramIndex}`];

                                        const inputProps = {
                                            type: "text",
                                            className: 'form-control',
                                            name: `param${paramIndex}`,
                                            id: `param${paramIndex}`,
                                            placeholder: paramNote,
                                            required: paramIsOptional,
                                            value: formData[`param${paramIndex}`],
                                            autoComplete: 'off',
                                            onChange: handleInputChange
                                        };

                                        if (paramRegEx) inputProps.pattern = paramRegEx;
                                        if (paramMinL) inputProps.minLength = paramMinL;
                                        if (paramMaxL) inputProps.maxLength = paramMaxL;

                                        return (
                                            <div key={paramIndex}>
                                                <input {...inputProps} />
                                            </div>
                                        );
                                    })}
                                </div>
                                <button type="submit" disabled={billfetchbtn} className='form-btn cursor-pointer'>
                                    {billfetchbtn ? (
                                        <div className="loading-btn"></div>
                                    ) : (
                                        <div className='btn-text'>Fetch Bill</div>
                                    )}
                                </button>
                            </form>
                        </div>
                        {billDetails && (
                            <div className='form_div'>
                                <div>
                                    <h4 style={{ textDecoration: 'underline', textAlign: 'center', marginBottom: '5px', marginTop: '5px' }}>Bill Details</h4>
                                    <p><strong>Customer:</strong> {billDetails.customer}</p>
                                    <p><strong>Bill Date:</strong> {billDetails.billdate}</p>
                                    <p><strong>Bill Number:</strong> {billDetails.billnumber}</p>
                                    <p><strong>Due Date:</strong> {billDetails.duedate}</p>
                                    <input
                                        type="number"
                                        className='form-control'
                                        autoComplete='off'
                                        onChange={finalAmountHandle}
                                        value={finalAmount}
                                    />
                                </div>
                                <button className='form-btn cursor-pointer' disabled={billpaybtn} onClick={handlePayment}>
                                    {billpaybtn ? (
                                        <div className="loading-btn"></div>
                                    ) : (
                                        <div className='btn-text'>Pay Now</div>
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BbpsForm;
