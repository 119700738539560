// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3px 10px 0px;
}

.margin_card {
  display: flex;
  justify-content: space-between; /* Ensure space between content */
  align-items: center; /* Center items vertically */
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: calc(100% - 20px);
  margin: 2px 0px 2px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  gap: 10px;
}

.biller_info {
  display: flex;
  flex-direction: column;
}

.biller_name {
  font-size: 14px;
  font-weight: bold;
}

.biller_mode {
  font-size: 12px;
  color: gray;
}

.margin {
  text-wrap: nowrap;
  font-weight: bold;
  margin-left: auto; 
  font-size: 11px;
  padding: 5px;
  background-color: #eeeaea;
  border-radius: 5px;
  color: black;
  height: 20px;
  min-width: 40px;
  align-content: center;
  text-align: center;
}

.company-logo {
  height: 40px;
  width: 40px;
}

.loading {
  font-size: 16px;
  font-weight: bold;
  color: gray;
}

/* Responsive design for mobile devices */
@media (max-width: 600px) {
  .custom-table {
    font-size: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/style/margin.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,8BAA8B,EAAE,iCAAiC;EACjE,mBAAmB,EAAE,4BAA4B;EACjD,uBAAuB;EACvB,sBAAsB;EACtB,kBAAkB;EAClB,wBAAwB;EACxB,mBAAmB;EACnB,aAAa;EACb,wCAAwC;EACxC,0BAA0B;EAC1B,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,eAAe;EACf,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;AACb;;AAEA,yCAAyC;AACzC;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".table_div {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 3px 10px 0px;\n}\n\n.margin_card {\n  display: flex;\n  justify-content: space-between; /* Ensure space between content */\n  align-items: center; /* Center items vertically */\n  background-color: white;\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  width: calc(100% - 20px);\n  margin: 2px 0px 2px;\n  padding: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  transition: transform 0.2s;\n  gap: 10px;\n}\n\n.biller_info {\n  display: flex;\n  flex-direction: column;\n}\n\n.biller_name {\n  font-size: 14px;\n  font-weight: bold;\n}\n\n.biller_mode {\n  font-size: 12px;\n  color: gray;\n}\n\n.margin {\n  text-wrap: nowrap;\n  font-weight: bold;\n  margin-left: auto; \n  font-size: 11px;\n  padding: 5px;\n  background-color: #eeeaea;\n  border-radius: 5px;\n  color: black;\n  height: 20px;\n  min-width: 40px;\n  align-content: center;\n  text-align: center;\n}\n\n.company-logo {\n  height: 40px;\n  width: 40px;\n}\n\n.loading {\n  font-size: 16px;\n  font-weight: bold;\n  color: gray;\n}\n\n/* Responsive design for mobile devices */\n@media (max-width: 600px) {\n  .custom-table {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
