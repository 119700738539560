import React, { useState, useEffect } from 'react';
import HeaderLogin from "../components/HeaderLogin";
import LoginIcon from '../assets/login.gif';
import '../style/login.css';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const API_URL = process.env.REACT_APP_API_URL;

const OtpVerification = () => {
    const [loginbtn, setLoginBtn] = useState(false);
    const location = useLocation();
    const { number } = location.state || {};
    console.log(number);
    const [formValue, setFormValue] = useState({
        number: number,
        otp: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValue({
            ...formValue,
            [name]: value,
        });
    };

    const HandleLogin = (event) => {
        if (formValue.otp.length === 6) {
            VerifyOtp(formValue.otp);
        } else {
            toast.error('Enter valid input.');
        }
    };

    async function VerifyOtp(otp) {
        setLoginBtn(true);
        try {
            const response = await fetch(API_URL + 'otp_verification.php?mobile=' + formValue.number + '&otp=' + formValue.otp + '&version=1');
            const data = await response.json();
            if (data.status === 'Failed') {
                Swal.fire('Ohh no', data.message, 'error');
            } else {
                localStorage.setItem('key', data.token);
                window.location.replace('../home');
            }
        } catch (error) {
            Swal.fire('Ohh no', 'Something went wrong.', 'error');
        } finally {
            setLoginBtn(false);
        }
    }

    return (
        <div style={{ backgroundColor: 'white' }}>
            <HeaderLogin title='Verification' />
            <div className='page-container'>
                <div className='form_div'>
                    <div className='content-center'>
                        <img src={LoginIcon} alt='' className='updateIcon' />
                    </div>
                    <div>
                        <input
                            type="hidden"
                            name="number"
                            maxLength='10'
                            placeholder='Enter number'
                            className={'form-control'}
                            autoComplete='off'
                            value={formValue.number}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <input
                            type="number"
                            name="otp"
                            maxLength='6'
                            placeholder='Enter otp'
                            className={'form-control'}
                            autoComplete='off'
                            value={formValue.otp}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div style={{ margin: '10px', color: 'var(--app-color)', textAlign: 'center' }}>
                        <p>Enter the OTP We've sent to {formValue.number}</p>
                    </div>
                    <button className='form-btn' onClick={HandleLogin} disabled={loginbtn}>
                        {loginbtn ? (
                            <div className="loading-btn"></div>
                        ) : (
                            <div className='btn-text'>Verify OTP</div>
                        )}
                    </button>
                </div>
            </div>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 3000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }}
            />
        </div>
    );
}

export default OtpVerification;
