import React from 'react'
import { Link } from 'react-router-dom'
import '../style/com-card.css'

const ReportCard = (props) => {
    return (
        <Link to={props.url} className='card-profile'>
            <div className='text-app'>
                {props.icon}
            </div>
            <div className='card-profile-header'>
                <h4>{props.title}</h4>
                <p style={{ fontSize: '13px' }}>{props.desc}</p>
            </div>
        </Link>
    )
}

export default ReportCard