// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wallet-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0px 0px 5px;
  padding: 15px;
  border-radius: 8px;
  height: 30px;
  width: calc(100%-20px);
}

.wallet-info {
  display: flex;
  align-items: center;
  flex: 1 1; /* Take up remaining space */
}

.wallet-name {
  font-weight: bold;
  margin-right: 10px;
}

.wallet-balance {
  color: red; /* Red color for balance */
}

.radio-input {
  margin-right: 10px; /* Adjust spacing between radio button and wallet info */
}
`, "",{"version":3,"sources":["webpack://./src/style/wallet-transfer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,uBAAuB;EACvB,sBAAsB;EACtB,wCAAwC;EACxC,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAO,EAAE,4BAA4B;AACvC;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,UAAU,EAAE,0BAA0B;AACxC;;AAEA;EACE,kBAAkB,EAAE,wDAAwD;AAC9E","sourcesContent":[".wallet-card {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background-color: white;\n  border: 1px solid #ddd;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  margin: 0px 0px 5px;\n  padding: 15px;\n  border-radius: 8px;\n  height: 30px;\n  width: calc(100%-20px);\n}\n\n.wallet-info {\n  display: flex;\n  align-items: center;\n  flex: 1; /* Take up remaining space */\n}\n\n.wallet-name {\n  font-weight: bold;\n  margin-right: 10px;\n}\n\n.wallet-balance {\n  color: red; /* Red color for balance */\n}\n\n.radio-input {\n  margin-right: 10px; /* Adjust spacing between radio button and wallet info */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
