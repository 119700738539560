// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recomended_amount_list {
    margin: 5px 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.recomended_amount {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    padding: 5px;
    width: 50px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/style/top-up.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,sBAAsB;IACtB,wCAAwC;IACxC,0BAA0B;IAC1B,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".recomended_amount_list {\n    margin: 5px 10px 10px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n}\n\n.recomended_amount {\n    background-color: white;\n    border-radius: 5px;\n    border: 1px solid #ddd;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    transition: transform 0.2s;\n    padding: 5px;\n    width: 50px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
