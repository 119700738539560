import React, { useEffect, useState } from 'react'
import HeaderActivity from '../components/HeaderActivity'
import Balance from '../components/Balance'
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import '../style/top-up.css'
import { useNavigate } from 'react-router-dom';


const API_URL = process.env.REACT_APP_API_URL;

const isWebView = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isAndroidWebView = /wv|Version/.test(userAgent) && /Android/.test(userAgent);
    const isIOSWebView = (
        /iPhone|iPod|iPad/.test(userAgent) &&
        /AppleWebKit/.test(userAgent) &&
        !/Safari/.test(userAgent)
    ) || /GSA/.test(userAgent);
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;
    return (isAndroidWebView || isIOSWebView) && !isStandalone;
};

const TopUp = () => {
    const [addmoneybtn, setAddmoneyBtn] = useState(false);
    const [entered_amount, setAmount] = useState('');
    const navigator = useNavigate();

    const [isWebViewState, setIsWebViewState] = useState(false);
    useEffect(() => {
        setIsWebViewState(isWebView());
    }, []);


    const handleChange = (event) => {
        setAmount(event.target.value);
    }

    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var amount = urlParams.get('amount');
    var upgrade = urlParams.get('upgrade');

    if (amount !== '' && upgrade !== '') {

    }

    async function getPaymentUlr() {
        setAddmoneyBtn(true);
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}top_up.php?token=${key}&version=1&amount=${entered_amount}`); // Replace with your API endpoint
            const data = await response.json();
            if (data.status === 'Failed') {
                Swal.fire('Failed', data.message, 'error');
            } else {
                if (data.mode === 'page') {
                    const url = btoa(data.url);

                    if (isWebViewState) {
                        window.location.href = "newtab:" + data.url;
                    } else {
                        window.open(data.url, '_blank', 'noopener,noreferrer');
                    }
                } else if (data.mode === 'intent') {
                    navigator('../check-out', { state: { url: data.url, order_id: data.order_id } });
                }
            }
        } catch (error) {
            Swal.fire('Ohh no', 'Something went wrong.', 'error');
        } finally {
            setAddmoneyBtn(false);
        }
    }

    const HandleAddmoney = (event) => {
        const pattern = /^[0-9]*$/;
        if (entered_amount !== "" && parseInt(entered_amount) > 0) {
            if (pattern.test(entered_amount)) {
                getPaymentUlr();
            } else {
                toast.error('Enter enter only number.');
            }
        } else {
            toast.error('Enter valid input.');
        }
    }

    const handleRecomend = (event) => {
        setAmount(event.target.innerText);
    }


    return (
        <div>
            <HeaderActivity title='Add money' />
            <div className='page-container'>
                <Balance />
                <div className='form_div'>
                    <div>
                        <input
                            type="number"
                            name="number"
                            placeholder='Enter amount'
                            className={'form-control'}
                            autoComplete='off'
                            value={entered_amount}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className='recomended_amount_list'>
                        <div className='recomended_amount' onClick={handleRecomend}>500</div>
                        <div className='recomended_amount' onClick={handleRecomend}>1000</div>
                        <div className='recomended_amount' onClick={handleRecomend}>3000</div>
                        <div className='recomended_amount' onClick={handleRecomend}>5000</div>
                        <div className='recomended_amount' onClick={handleRecomend}>10000</div>
                    </div>
                    <button className='form-btn cursor-pointer' disabled={addmoneybtn} onClick={HandleAddmoney}>
                        {addmoneybtn ? (
                            <div className="loading-btn"></div>
                        ) : (
                            <div className='btn-text'>Pay Now</div>
                        )}
                    </button>
                </div>
            </div>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 3000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }}
            />
        </div>
    )
}

export default TopUp