import React, { useEffect, useState, useRef, useCallback } from 'react';
import Menu from '../components/Menu';
import Header from '../components/Header';
import '../style/margin.css';
import ErrorPage from '../components/ErrorPage';

const API_URL = process.env.REACT_APP_API_URL;

const Margin = () => {
  document.title = "Margin";
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error_page, setError] = useState(false);
  const [error_msg, setErrorMsg] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const observer = useRef();
  const typingTimeoutRef = useRef(null);

  const fetchTransactions = async (page, query = '') => {
    const key = localStorage.getItem('key');
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}operator.php?token=${key}&version=1&page=${page}&query=${query}`);
      const data = await response.json();
      if (data.data1.length > 0) {
        setTransactions(prevTransactions => [...prevTransactions, ...data.data1]);
      } else {
        setHasMore(false);
      }

      setError(false);
      setErrorMsg('');      
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    setTransactions([]);
    setPage(1);
    setHasMore(true);
    fetchTransactions(1, searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    if (page === 1) return;
    fetchTransactions(page, searchQuery);
  }, [page, searchQuery]);

  const lastTransactionElementRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore && !loading) {
        setPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [hasMore, loading]);

  const handleSearchChange = (e) => {
    const input = e.target.value;
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setSearchQuery(input);
    }, 500);
  };

  return (
    <div>
      {error_page ? (
        <ErrorPage message={error_msg} callback={() => fetchTransactions(page,searchQuery)}/>
      ) : (
        <div>
          <Header />
          <Menu />
          <div className='main-container'>
            <div style={{ padding: '6px 10px 0px' }}>
              <input
                type="text"
                placeholder='Search here...'
                className='form-control'
                autoComplete='off'
                required
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            <div className='table_div'>
              {transactions.map((item, index) => (
                <div className='margin_card' key={item.biller_id}
                  ref={index === transactions.length - 1 ? lastTransactionElementRef : null}>
                  <div>
                    <img src={item.logo} alt='biller' className="company-logo" />
                  </div>
                  <div className='biller_info'>
                    <div className="biller_name">{item.operator}</div>
                    <div className="biller_mode">{item.mode}</div>
                  </div>
                  <div className="margin">{item.margin + "" + item.commission_type}</div>
                </div>
              ))}
              {loading && <center className="loading"></center>}
              {!hasMore && !loading && <center>No more operator.</center>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Margin;
