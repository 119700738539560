import React, { useState, useEffect } from 'react';
import VerifiedIcon from '@mui/icons-material/Verified';
import BarChartIcon from '@mui/icons-material/BarChart';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import '../style/com-transaction-sumary.css'

const API_URL = process.env.REACT_APP_API_URL;

const TransactionSummary = () => {
  const [balances, setBalances] = useState({
    balance: '0.00',
    topup_used: '0.00',
    offer: '0.00'
  });
  

  useEffect(() => {
    const key = localStorage.getItem('key');
    const fetchBalance = async () => {
      try {
        const response = await fetch(`${API_URL}balance.php?token=${key}&version=1`);
        const data = await response.json();
        if (data.status === 'Success') {
          setBalances(data.data1);
          localStorage.setItem('note', data.data2);
        } else {
          Swal.fire('Ohh no', data.message, 'error');
        }
      } catch (err) {
        Swal.fire('Ohh no', 'Someting went wrong.', 'error');
      }
    };

    fetchBalance();
  }, []);

  return (
    <div>
      <div className='home-balance-card'>
        <div className='home-balance-left'>
          <WalletIcon fontSize='large' />
          <div className='card-profile-header'>
            <p>Balance</p>
            <p>{balances.balance}</p>
          </div>
        </div>
        <Link to='../top-up' style={{color:'black',textDecoration:'none'}} className='home-balance-right'>
          <div className='home-balance-left-button'>
            <AddCircleIcon fontSize='small' />
            <p>Add Money</p>
          </div>
        </Link>
      </div>
      <div className='two-part'>
        <div className='card-profile' style={{ width: '50%' ,backgroundColor:'var(--app-color)',color:'white'}}>
          <VerifiedIcon />
          <div className='card-profile-header'>
            <p>Total Recharge</p>
            <p>{balances.topup_used}</p>
          </div>
        </div>
        <div className='card-profile' style={{ width: '50%' ,backgroundColor:'var(--app-color)',color:'white'}}>
          <BarChartIcon />
          <div className='card-profile-header'>
            <p>Earned</p>
            <p>{balances.offer}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionSummary