// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-balance-card {
    width: calc(100% - 20px);
    height: 75px;
    background-color: var(--app-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0px 5px 0px;
    overflow-x: auto;
    margin: 10px 10px 10px;
    border-radius: 5px;
    color: #FFFFFF;
}

.home-balance-left {
    padding: 12px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.home-balance-left-button {
    background-color: #eeeeee;
    color: black;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    gap: 6px;
}

.two-part {
    width: calc(100% - 10px);
    display: flex;
    margin: 0px 5px 0px;
    flex-direction: row;
}

`, "",{"version":3,"sources":["webpack://./src/style/com-transaction-sumary.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,YAAY;IACZ,kCAAkC;IAClC,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,sBAAsB;IACtB,oBAAoB;IACpB,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,wBAAwB;IACxB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".home-balance-card {\n    width: calc(100% - 20px);\n    height: 75px;\n    background-color: var(--app-color);\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    box-sizing: border-box;\n    padding: 0px 5px 0px;\n    overflow-x: auto;\n    margin: 10px 10px 10px;\n    border-radius: 5px;\n    color: #FFFFFF;\n}\n\n.home-balance-left {\n    padding: 12px;\n    align-items: center;\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n}\n\n.home-balance-left-button {\n    background-color: #eeeeee;\n    color: black;\n    border-radius: 5px;\n    display: flex;\n    flex-direction: row;\n    padding: 10px;\n    gap: 6px;\n}\n\n.two-part {\n    width: calc(100% - 10px);\n    display: flex;\n    margin: 0px 5px 0px;\n    flex-direction: row;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
