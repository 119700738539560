import React, { useState, useEffect } from 'react';
import HeaderActivity from '../components/HeaderActivity';
import '../style/wallet-transfer.css';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import ErrorPage from '../components/ErrorPage';

const API_URL = process.env.REACT_APP_API_URL;

const WalletTransfer = () => {
    const [wallets, setWallets] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [transferbtn, setTrabsferBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error_page, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchWalletData();
    }, []);

    const fetchWalletData = async () => {
        setLoading(true);
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}others/wallet-list.php?token=${key}&version=1`);
            const data = await response.json();
            if (data.status === 'Success') {
                setWallets(data.data1);

                setError(false);
                setErrorMsg('');
            } else {
                setError(true);
                setErrorMsg(data.message);
            }
        } catch (error) {
            setError(true);
            setErrorMsg(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        setSelectedWallet(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedWallet) {
            setTrabsferBtn(true);
            transferWallet(selectedWallet);
        } else {
            Swal.fire('Ohh no', 'Select at least one wallet', 'error');
        }
    };

    const transferWallet = async (selectedWallet) => {
        try {
            const key = localStorage.getItem('key'); // Retrieve key from localStorage
            const response = await fetch(`${API_URL}others/wallet-transfer.php?token=${key}&version=1&wallet=${selectedWallet}`);
            const data = await response.json();
            if (data.status === 'Success') {
                Swal.fire({
                    title: 'Success!',
                    text: 'Successfully transferred.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    window.location.reload();
                });
            } else {
                Swal.fire('Ohh no', data.message, 'error');
            }
        } catch (error) {
            Swal.fire('Ohh no', 'Technical issue occurs.', 'error');
        } finally {
            setTrabsferBtn(false);
        }
    };

    return (
        <div>
            {loading ? (
                <div className="loading_center">
                    <div className="loading"></div>
                </div>
            ) : error_page ? (
                <ErrorPage message={error_msg} callback={() => fetchWalletData()}/>
            ) : (
                <div>
                    <HeaderActivity title='Wallet Transfer' />
                    <div  style={{margin:'65px 10px 0px 10px'}}>
                        <form onSubmit={handleSubmit}>
                            {wallets.map((wallet) => (
                                <div key={wallet.id} className="wallet-card">
                                    <label className="wallet-info">
                                        <input
                                            type="radio"
                                            value={wallet.wallet_name}
                                            checked={selectedWallet === wallet.wallet_name}
                                            onChange={handleChange}
                                            className="radio-input"
                                        />
                                        <div className="wallet-name">{wallet.wallet_name}</div>
                                    </label>
                                    <div className="wallet-balance">₹ {parseFloat(wallet.balance).toFixed(2)}</div>
                                </div>
                            ))}
                            <button type="submit" className='form-btn cursor-pointer' disabled={transferbtn}>
                                {transferbtn ? (
                                    <div className="loading-btn"></div>
                                ) : (
                                    <div className='btn-text'>Send OTP</div>
                                )}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default WalletTransfer;
