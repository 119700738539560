import React from 'react'
import HeaderActivity from '../components/HeaderActivity'

const ComingSoon = () => {
  return (
    <div>
        <HeaderActivity title='Coming soon'/>
        <div className='text-div-center' style={{marginTop:'200px', textAlign:'center'}}>
            Coming soon
        </div>
    </div>
  )
}

export default ComingSoon