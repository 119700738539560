// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.company-card {
    display: flex;
    flex-direction: row;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: calc(100%-20px);
    margin: 5px 10px 5px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    gap: 10px;
    align-items: center;
}
.company-logo {
    height: 40px;
    width: 40px;
}`, "",{"version":3,"sources":["webpack://./src/style/biller-file.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;IACtB,oBAAoB;IACpB,aAAa;IACb,wCAAwC;IACxC,0BAA0B;IAC1B,SAAS;IACT,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,WAAW;AACf","sourcesContent":["\n.company-card {\n    display: flex;\n    flex-direction: row;\n    background-color: white;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    width: calc(100%-20px);\n    margin: 5px 10px 5px;\n    padding: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    transition: transform 0.2s;\n    gap: 10px;\n    align-items: center;\n}\n.company-logo {\n    height: 40px;\n    width: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
