import React from 'react'
import HeaderActivity from '../components/HeaderActivity'
import { Link } from 'react-router-dom'
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ShopIcon from '@mui/icons-material/Shop';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import PropaneTankIcon from '@mui/icons-material/PropaneTank';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import RouterIcon from '@mui/icons-material/Router';
import CableIcon from '@mui/icons-material/Cable';
import FaxIcon from '@mui/icons-material/Fax';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import SchoolIcon from '@mui/icons-material/School';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import ShieldIcon from '@mui/icons-material/Shield';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import Groups2Icon from '@mui/icons-material/Groups2';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import SanitizerIcon from '@mui/icons-material/Sanitizer';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import '../style/service.css'

const Services = () => {
    return (
        <div>
            <HeaderActivity title='Service' />
            <div className='page-container'>
                <div className='card-service-item'>
                    <div className='card-service-item-header'>
                        <span className='card-service-header-bar'></span>
                        <p>Prepaid</p>
                    </div>
                    <div className='service-item-row'>
                        <Link to='../recharge?type=prepaid&title=Prepaid' className='service-item-card'>
                            <div className='service-item-content'>
                                <SystemSecurityUpdateGoodIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Prepaid</p>
                        </Link>
                        <Link to='../dth_recharge?type=dth_recharge&title=DTH%20Recharge' className='service-item-card'>
                            <div className='service-item-content'>
                                <OndemandVideoIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>DTH</p>
                        </Link>
                        <Link to='../recharge?type=google_play&title=Google%20Play' className='service-item-card'>
                            <div className='service-item-content'>
                                <ShopIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Google Play</p>
                        </Link>
                        <Link to='../bbps-biller?category=Fastag&title=Fastag' className='service-item-card'>
                            <div className='service-item-content'>
                                <CarCrashIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Fastag</p>
                        </Link>
                    </div>
                </div>
                <div className='card-service-item'>
                    <div className='card-service-item-header'>
                        <span className='card-service-header-bar'></span>
                        <p>Utilities</p>
                    </div>
                    <div className='service-item-row'>
                        <Link to='../bbps-biller?category=Electricity&title=Electricity' className='service-item-card'>
                            <div className='service-item-content'>
                                <EmojiObjectsIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Electricity</p>
                        </Link>
                        <Link to='../recharge?type=postpaid&title=Postpaid' className='service-item-card'>
                            <div className='service-item-content'>
                                <SmartphoneIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Postpaid</p>
                        </Link>
                        <Link to='../bbps-biller?category=Broadband%20Postpaid&title=Broadband' className='service-item-card'>
                            <div className='service-item-content'>
                                <RouterIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Broadband</p>
                        </Link>
                        <Link to='../bbps-biller?category=LPG%20Gas&title=LPG%20Gas' className='service-item-card'>
                            <div className='service-item-content'>
                                <PropaneTankIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>LPG Gas</p>
                        </Link>
                    </div>
                    <div className='service-item-row'>
                        <Link to='../bbps-biller?category=Landline Postpaid&title=Landline' className='service-item-card'>
                            <div className='service-item-content'>
                                <FaxIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Lnadline</p>
                        </Link>
                        <Link to='../bbps-biller?category=Gas&title=Gas' className='service-item-card'>
                            <div className='service-item-content'>
                                <LocalGasStationIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Gas</p>
                        </Link>
                        <Link to='../bbps-biller?category=Education Fees&title=Education Fees' className='service-item-card'>
                            <div className='service-item-content'>
                                <SchoolIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Education</p>
                        </Link>
                        <Link to='../bbps-biller?category=Life Insurance&title=Life Insurance' className='service-item-card'>
                            <div className='service-item-content'>
                                <FamilyRestroomIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Life Insurance</p>
                        </Link>
                    </div>
                    <div className='service-item-row'>
                        <Link to='../bbps-biller?category=Insurance&title=Insurance' className='service-item-card'>
                            <div className='service-item-content'>
                                <ShieldIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Insurance</p>
                        </Link>
                        <Link to='../bbps-biller?category=Health Insurance&title=Health Insurance' className='service-item-card'>
                            <div className='service-item-content'>
                                <HealthAndSafetyIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Health Insurance</p>
                        </Link>
                        <Link to='../bbps-biller?category=Water&title=Water%20Bill' className='service-item-card'>
                            <div className='service-item-content'>
                                <WaterDropIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Water Bill</p>
                        </Link>
                        <Link to='../bbps-biller?category=Cable TV&title=Cable TV' className='service-item-card'>
                            <div className='service-item-content'>
                                <CableIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Cable TV</p>
                        </Link>
                    </div>
                </div>
                <div className='card-service-item'>
                    <div className='card-service-item-header'>
                        <span className='card-service-header-bar'></span>
                        <p>Finance & Tax</p>
                    </div>
                    <div className='service-item-row'>
                        
                        <Link to='../bbps-biller?category=Municipal Taxes&title=Municipal Tax'className='service-item-card'>
                            <div className='service-item-content'>
                                <ApartmentIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Municipal Taxes</p>
                        </Link>
                        <Link to='../bbps-biller?category=Loan Repayment&title=Loan Repayment' className='service-item-card'>
                            <div className='service-item-content'>
                                <CreditScoreIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>EMI</p>
                        </Link>
                        <Link to='../bbps-biller?category=Credit Card&title=Credit Card' className='service-item-card'>
                            <div className='service-item-content'>
                                <CreditCardIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Credit Card</p>
                        </Link>
                        <Link to='../bbps-biller?category=Municipal Services&title=Municipal Service' className='service-item-card'>
                            <div className='service-item-content'>
                                <ApartmentIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Municipal Services</p>
                        </Link>
                    </div>
                </div>
                <div className='card-service-item'>
                    <div className='card-service-item-header'>
                        <span className='card-service-header-bar'></span>
                        <p>Subscription</p>
                    </div>
                    <div className='service-item-row'>
                        <Link to='../bbps-biller?category=Subscription&title=Subscription'  className='service-item-card'>
                            <div className='service-item-content'>
                                <SubscriptionsIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Subscription</p>
                        </Link>
                        <Link to='../bbps-biller?category=Clubs and Associations&title=Clubs and Association' className='service-item-card'>
                            <div className='service-item-content'>
                                <Diversity3Icon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Clubs & Association</p>
                        </Link>
                        <Link to='../bbps-biller?category=Housing Society&title=Housing Society'  className='service-item-card'>
                            <div className='service-item-content'>
                                <Groups2Icon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Housing Society</p>
                        </Link>
                        <Link to='../bbps-biller?category=Hospital and Pathology&title=Hospital and Pathology'  className='service-item-card'>
                            <div className='service-item-content'>
                                <SanitizerIcon fontSize='large' />
                            </div>
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Pathology</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services