import React from 'react'
import {NavLink} from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home';
import InvestIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import ReportIcon from '@mui/icons-material/Report';
import '../App.css'


const BottomMenu = () => {
  return (
    <nav>
      <NavLink className='nav_link' to='/home'><HomeIcon fontSize="medium"/><span>Home</span></NavLink>
      <NavLink className='nav_link' to='/margin'><InvestIcon fontSize="medium"/><span>Margin</span></NavLink>
      <NavLink className='nav_link' to='/report'><ReportIcon fontSize="medium"/><span>Report</span></NavLink>
      <NavLink className='nav_link' to='/setting'><SettingsIcon fontSize="medium"/><span>Setting</span></NavLink>
    </nav>
  )
}

export default BottomMenu