// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transaction-summary-container {
    width: calc(100%-10px);
    margin: 0px 10px 10px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}
.transaction-summary {
    display: flex;
    flex-wrap: nowrap;
}
body::-webkit-scrollbar {
    display: none;
}
.transaction-summary-container::-webkit-scrollbar {
    display: none;
}
.transaction-card {
    color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    width: 121px;
    height: 55px;
    text-align: center;
    margin-right: 5px;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
}

.transaction-card p {
    margin: 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/style/com-balance.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,iBAAiB;AACrB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;AACjB;AACA;IACI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".transaction-summary-container {\n    width: calc(100%-10px);\n    margin: 0px 10px 10px;\n    overflow-x: auto;\n    overflow-y: hidden;\n    white-space: nowrap;\n}\n.transaction-summary {\n    display: flex;\n    flex-wrap: nowrap;\n}\nbody::-webkit-scrollbar {\n    display: none;\n}\n.transaction-summary-container::-webkit-scrollbar {\n    display: none;\n}\n.transaction-card {\n    color: white;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    padding: 10px;\n    width: 121px;\n    height: 55px;\n    text-align: center;\n    margin-right: 5px;\n    flex-shrink: 0;\n    align-items: center;\n    justify-content: center;\n}\n\n.transaction-card p {\n    margin: 5px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
