import React, { useEffect, useState } from 'react';
import '../style/invoice.css';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DownloadIcon from '@mui/icons-material/Download';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ErrorPage from '../components/ErrorPage';
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';

const API_URL = process.env.REACT_APP_API_URL;

const Invoice = () => {
    const [loading, setLoading] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const { tnx_sl } = location.state || {};
    const [orderInfo, setOrderInfo] = useState(null);

    useEffect(() => {
        if (!tnx_sl) {
            navigate(-1);
        }
    }, [tnx_sl, navigate]);

    const getOrderInfo = async () => {
        setLoading(true);
        const key = localStorage.getItem('key');
        try {
            const response = await fetch(`${API_URL}transaction-via-orderid.php?token=${key}&version=1&page=1&tnx_sl=${tnx_sl}`);
            const data = await response.json();
            if (data.status === 'Success') {
                console.log(data.data1);
                setOrderInfo(data.data1);

                setErrorPage(false);
                setErrorMsg('');
            } else {
                setErrorPage(true);
                setErrorMsg(data.message);
            }
        } catch (err) {
            setErrorPage(true);
            setErrorMsg(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getOrderInfo();
    }, []);

    const getTransactionStatus = () => {
        if (orderInfo.status === "1") {
            return 'Transaction Successful';
        } else if (orderInfo.status === "3") {
            return 'Transaction Failed';
        } else {
            return 'Transaction Pending';
        }
    };

    const getStatusStyle = () => {
        if (orderInfo.status === "1") {
            return { backgroundColor: 'green' };
        } else if (orderInfo.status === "3") {
            return { backgroundColor: 'red' };
        } else {
            return { backgroundColor: '#FFC107' };
        }
    };

    const raiseComplaint = (event) => {
        Swal.fire({
            title: 'Enter your query',
            input: 'text',
            inputValue: 'Default',
            showCancelButton: true,
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
        }).then(async (result) => { // Corrected syntax
            if (result.isConfirmed) {
                const inputText = result.value;
                if (inputText != "") {
                    const key = localStorage.getItem('key');
                    try {
                        const response = await fetch(`${API_URL}complaint.php?token=${key}&version=1&order_id=${orderInfo.order_id}&query=${inputText}`);
                        const data = await response.json();

                        if (data.status === 'Success') {
                            Swal.fire('Success', data.message, 'success');
                        } else {
                            Swal.fire('Failed', data.message, 'error');
                        }
                    } catch (error) {
                        Swal.fire('Ohh no', 'Something went wrong.', 'error');
                    }
                } else {
                    toast.error('Enter valid input.');
                }
            }
        });
    };


    return (
        <div>
            {loading ? (
                <div className="loading_center">
                    <div className="loading"></div>
                </div>
            ) : errorPage ? (
                <ErrorPage message={errorMsg} callback={() => getOrderInfo}/>
            ) : (
                orderInfo && (
                    <div>
                        <div className="status_div" style={getStatusStyle()}>
                            <ArrowBackIosIcon style={{ zIndex: '1' }} className='backbutton' onClick={() => navigate(-1)} />
                            <div className="center_container">
                                <img className="status_icon" src={orderInfo.logo} alt="Status Icon" loading='lazy' />
                                <p>{getTransactionStatus()}</p>
                            </div>
                            <div className='transaction_heading'>
                                <h3>₹ {orderInfo.amount}</h3>
                                <h3 style={{ textTransform: 'uppercase', marginTop: '3px', marginBottom: '3px' }}>{orderInfo.desc ? (orderInfo.desc) : ('NA')}</h3>
                                <p>Txn No: {orderInfo.txn_no ? (orderInfo.txn_no) : ('NA')}</p>
                            </div>
                        </div>
                        <div className='order_details'>
                            <div className='share_row'>
                                <p><b>From: Wallet</b></p>
                                <DownloadIcon />
                            </div>
                            <p>Number: {orderInfo.number ? (orderInfo.number) : ('NA')}</p>
                            <p>Name: {orderInfo.name ? (orderInfo.name) : ('NA')}</p>
                            <p>Time: {orderInfo.time}</p>
                            <p>Biller: {orderInfo.biller_name ? (orderInfo.biller_name) : ('NA')}</p>
                            <p>Transaction: {orderInfo.biller_category ? (orderInfo.biller_category) : ('NA')}</p>
                        </div>
                        <div className='order_rewards'>
                            <p><EmojiEventsIcon /> Rewards</p>
                            <div>₹ {orderInfo.offer ? (orderInfo.offer) : (0.00)}</div>
                        </div>
                        <div className='order_dispute' onClick={() => raiseComplaint()}>
                            <p><AnnouncementIcon /> Raise Complaint</p>
                        </div>
                        <div className='order_dispute' onClick={() => window.location.href = `https://wa.me/917578931458?text=*Support for the query*\n${orderInfo.desc}`}>
                            <p><WhatsAppIcon className='whatsapp_icon' /> WhatsApp Support</p>
                        </div>
                        <Toaster
                            position="bottom-center"
                            reverseOrder={false}
                            gutter={8}
                            containerClassName=""
                            containerStyle={{}}
                            toastOptions={{
                                className: '',
                                duration: 3000,
                                style: {
                                    background: '#363636',
                                    color: '#fff',
                                },
                                success: {
                                    duration: 3000,
                                    theme: {
                                        primary: 'green',
                                        secondary: 'black',
                                    },
                                },
                            }}
                        />
                    </div>
                )
            )}
        </div>
    );
};

export default Invoice;
