import React, { useEffect, useState } from 'react';
import '../style/biller-file.css';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderActivity from '../components/HeaderActivity'
import Swal from 'sweetalert2';
import ErrorPage from '../components/ErrorPage';

const API_URL = process.env.REACT_APP_API_URL;

const BbpsBiller = () => {
    const [companies, setCompanies] = useState([]);
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(false);
    const [error_page, setError] = useState(false);
    const [error_msg , setErrorMsg] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [category,setCategory] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setTitle(params.get('title'));

        if (params.get('category')) {
            fetchCompanies(params.get('category'));
            setCategory(params.get('category'));
        } else {
            Swal.fire({
                title: 'Failed!',
                text: 'Technical issue occurs.',
                icon: 'error',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(-1);
                }
            });
        }
    }, [location]);

    const fetchCompanies = async (pass_category) => {
        setLoading(true);
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}bbps-operator.php?token=${key}&version=1&category=${pass_category}`); // Replace with your API endpoint
            const data = await response.json();
            if (data.status === 'Success') {
                setCompanies(data.data1);
                setError(false);
                setErrorMsg('');
            } else {
                setError(true);
                setErrorMsg(data.message);
            }
        } catch (error) {
            setError(true);
            setErrorMsg(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleCardClick = (biller_id) => {
        navigate(`../bbps-form?biller_id=${biller_id}`);
    };

    return (
        <div>
            {loading ? (
                <div className="loading_center">
                    <div className="loading"></div>
                </div>
            ) : error_page ? (
                <ErrorPage message={error_msg} callback={() => fetchCompanies(category)}/>
            ) : (
                <div>
                    <HeaderActivity title={title} />
                    <div className='page-container'>
                        {companies.length === 0 ? (
                            <center>No records found</center>
                        ) : (
                            companies.map(company => (
                                <div key={company.biller_id} onClick={() => handleCardClick(company.biller_id)} className="company-card" >
                                    <img src={company.logo} alt={`${company.operator} logo`} className="company-logo" />
                                    <h5>{company.operator}</h5>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BbpsBiller;
